<template>

  <component :is="computedNotificationTemplateComponent"
             v-bind="$props"
    :notification="safeNotification"
  >

  </component>
 <!--
  <div v-if="notification && finalLayout === 'default'">notification (default</div>
  <div v-if="notification && finalLayout === 'timeline'">notification (timeline)</div>
  <div v-if="notification && finalLayout === 'intro'">notification (intro)</div>
  -->
</template>

<script>
export default {
  props: {
    layout: {
      type: String,
      default: "default",
    },
    notification: {
      type: [Object, Boolean],
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      supportedLayouts: ["default", "timeline", "intro"],
    };
  },
  computed: {
    computedNotificationTemplateComponent() {
      return utilities.ucFirst(this.notification.type) + 'NotificationItem';
    },
    finalLayout() {
      if (this.supportedLayouts.includes(this.layout)) {
        return this.layout;
      }

      return "default";
    },
    safeNotification() {
      return this.notification;
    }
  },
};
</script>

<style scoped lang="scss"></style>
